//slick slider js  
$(document).ready(function () {
  $('.slick-carousel').slick({
    arrows: false,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('.header-carousel').slick({
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  });

  //header submenu hover
  $('.expand-hover').mouseenter(
    function () {
      $(this).children(".sub-menu").addClass("show-list");
    });
  $('.expand-hover').mouseleave(
    function () {
      $(this).children(".sub-menu").removeClass("show-list");
    });



  $('.box').mouseenter(function () {
    $(this).children(".icons").css({
      "background-size": "auto 95%"
    });
  });
  $('.box').mouseleave(function () {
    $(this).children(".icons").css({
      "background-size": "auto 85%"
    });
  });


  //set large font 
  //change local to local to persist when tab is closed
  if (localStorage.getItem("largeFont") == 1) {
    document.documentElement.style.setProperty('--ParagraphFontSize', '3rem');
    document.documentElement.style.setProperty('--h2FontSize', '4.5rem');
    document.documentElement.style.setProperty('--buttonFontSize', '4rem');
    // document.documentElement.style.setProperty('--lineHeight', '2.5');
  } else {
    document.documentElement.style.setProperty('--ParagraphFontSize', '2rem');
    document.documentElement.style.setProperty('--h2FontSize', '3rem');
    document.documentElement.style.setProperty('--buttonFontSize', '2.5rem');
    // document.documentElement.style.setProperty('--lineHeight', '1.5');
  }
  $('#font-size').click(function () {
    if (localStorage.getItem("largeFont") == 0) {
      document.documentElement.style.setProperty('--ParagraphFontSize', '3rem');
      document.documentElement.style.setProperty('--h2FontSize', '4.5rem');
      document.documentElement.style.setProperty('--buttonFontSize', '4rem');
      // document.documentElement.style.setProperty('--lineHeight', '2.5');
      localStorage.setItem("largeFont", "1");
    } else {
      document.documentElement.style.setProperty('--ParagraphFontSize', '2rem');
      document.documentElement.style.setProperty('--h2FontSize', '3rem');
      document.documentElement.style.setProperty('--buttonFontSize', '2.5rem');
      // document.documentElement.style.setProperty('--lineHeight', '1.5');
      localStorage.setItem("largeFont", "0");
    }
  });
  //set high contrast for page
  //change local to local to persist when tab is closed
  if (localStorage.getItem("contrast") == 1) {
    document.documentElement.style.setProperty('--paragraphColor', '#000000');
    document.documentElement.style.setProperty('--darkToWhite', '#fafafa');
    document.documentElement.style.setProperty('--whiteToDark', '#004D5D');
    document.documentElement.style.setProperty('--midBlue', '#0093B2');
    document.documentElement.style.setProperty('--boxBlue', '#e7f5f8');
    $(".icons").addClass('dark');
  } else {
    document.documentElement.style.setProperty('--paragraphColor', '#fafafa');
    document.documentElement.style.setProperty('--darkToWhite', '#004D5D');
    document.documentElement.style.setProperty('--whiteToDark', '#fafafa');
    document.documentElement.style.setProperty('--midBlue', '#036c83');
    document.documentElement.style.setProperty('--boxBlue', '#005465');
    $(".icons").removeClass('dark');
  }
  $('#contrast').click(function () {
    if (localStorage.getItem("contrast") == 0) {
      document.documentElement.style.setProperty('--paragraphColor', '#000000');
      document.documentElement.style.setProperty('--darkToWhite', '#fafafa');
      document.documentElement.style.setProperty('--whiteToDark', '#004D5D');
      document.documentElement.style.setProperty('--midBlue', '#0093B2');
      document.documentElement.style.setProperty('--boxBlue', '#e7f5f8');
      $(".icons").addClass('dark');
      localStorage.setItem("contrast", "1");
    } else {
      document.documentElement.style.setProperty('--paragraphColor', '#fafafa');
      document.documentElement.style.setProperty('--darkToWhite', '#004D5D');
      document.documentElement.style.setProperty('--whiteToDark', '#fafafa');
      document.documentElement.style.setProperty('--midBlue', '#036c83');
      document.documentElement.style.setProperty('--boxBlue', '#005465');
      $(".icons").removeClass('dark');

      localStorage.setItem("contrast", "0");
    }
  });
  // Add smooth scrolling to all links
  $(".quicknav").on('click', function (event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function () {

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });


});